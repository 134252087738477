<template>
  <div>
    <ve-histogram
      :settings="barSettings"
      height="260px"
      width="300px"
      :extend="barExtend"
      :data="datas"
      :yAxis="yAxis"
    />
  </div>
</template>

<script>
import VeHistogram from 'v-charts/lib/histogram.common';

export default {
  name: 'pieEchart',
  components: {
    VeHistogram,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: () => '',
    },
  },
  async created() {
    this.getData();
  },
  methods: {
    getData() {
      const { name } = this;
      const { list } = this;
      if (name == '红闪训练—视标模式' || name == '光栅训练—视标模式') {
        const data = [];
        const rows = [];
        for (let i = 0; i < list.length; i++) {
          data.push(list[i].name.slice(4));
          rows.push({ name: list[i].训练眼别, value: list[i].视标最高等级 });
        }
        this.barExtend = {
          grid: {
            top: '5%',
            left: '1%',
            bottom: '4%',
            right: '2%',
          },
          xAxis: {
            data,
            type: 'category',
            position: 'bottom',
            show: true,
            axisLabel: {
              position: 'bottom',
              show: true,
              interval: 'auto',
              left: 'center',
              color: '#000',
            },
            axisLine: {
              show: true,
              symbol: ['none', 'none'],
              lineStyle: {
                type: 'dashed',
                color: '#B2B1C5',
              },
            },
            axisPointer: {
              shadowStyle: {
                color: '#000',
              },
            },
          },
          yAxis: {
            type: 'category',
            show: true,
            position: 'left',
            boundaryGap: false,
            data: ['20/100', '20/80', '20/60', '20/50', '20/40', '20/30'],
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
              },
            },
            axisTick: {
              alignWithLabel: true,
            },
          },
          // 每个柱子
          series(v) {
            // 设置柱子的样式
            v.forEach((i) => {
              i.barWidth = 15;
              i.barMinHeight = 2;
              i.label = {
                show: true,
                position: 'top',
                offset: [0, 5],
                formatter: (e) => {
                  let subfix = '';
                  if (list[e.dataIndex].训练眼别 === '双眼' || list[e.dataIndex].训练眼别 === 'OU') {
                    subfix = '双';
                  } else if (list[e.dataIndex].训练眼别 === '右眼' || list[e.dataIndex].训练眼别 === 'OD') {
                    subfix = '右';
                  } else {
                    subfix = '左';
                  }
                  return subfix;
                },
              };
              i.itemStyle = {
                barBorderRadius: [10, 10, 0, 0],
                color: (e) => {
                  let colors = '';
                  if (list[e.dataIndex].训练眼别 === '双眼' || list[e.dataIndex].训练眼别 === 'OU') {
                    colors = '#FCC51F';
                  } else if (list[e.dataIndex].训练眼别 === '右眼' || list[e.dataIndex].训练眼别 === 'OD') {
                    colors = '#434ABC';
                  } else {
                    colors = '#FF6A7A';
                  }
                  return colors;
                },
              };
            });
            return v;
          },
          tooltip: {
            show: true,
            formatter: (v) => {
              const [first] = v;
              const key = this.getTooltip(first.axisValue);
              return key;
            },
          },
          legend: {
            show: false,
          },
        };
        this.yAxis = {
          type: 'category',
          show: true,
          position: 'left',
          boundaryGap: false,
          data: ['20/100', '20/80', '20/60', '20/50', '20/40', '20/30'],
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
            },
          },
          axisTick: {
            alignWithLabel: true,
          },
        };
        this.datas = {
          columns: ['name', 'value'],
          rows,
        };
      } else if (name == '脱抑制训练—红绿阅读') {
        const data = [];
        const rows = [];
        for (let i = 0; i < list.length; i++) {
          data.push(list[i].name.slice(5));
          let key = {};
          key = {
            value1: list[i].左眼速度,
            value2: list[i].右眼速度,
          };
          rows.push({ name: list[i].训练项目, ...key });
        }
        this.barExtend = {
          color: ['#ff6a7a', '#434abc'],
          grid: {
            top: '2%',
            bottom: '4%',
            right: '24%',
          },
          // 每个柱子
          series(v) {
            v.forEach((i) => {
              i.barWidth = 15;
              i.barMinHeight = 2;
              i.label = {
                show: true,
                position: 'right',
                offset: [-5, 0],
                formatter: (params) => {
                  let subfix = '';
                  if (params.color === '#FCC51F' || params.color === '#EB903E') {
                    subfix = '双';
                  } else if (params.color === '#434abc' || params.color === '#7070F5') {
                    subfix = '右';
                  } else {
                    subfix = '左';
                  }
                  return subfix;
                },
              };
              i.itemStyle = {
                barBorderRadius: [0, 10, 10, 0],
              };
            });
            return v;
          },
          xAxis: {
            type: 'value',
            show: true,
            name: '阅读速度',
            nameLocation: 'end',
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              width: 50,
              interval: 'auto',
              color: '#000',
              overflow: 'break',
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
              },
            },
          },
          yAxis: {
            type: 'category',
            show: true,
            data,
            axisLabel: {
              show: true,
              width: 50,
              interval: 'auto',
              color: '#000',
              overflow: 'break',
            },
            axisLine: {
              show: true,
              symbol: ['none', 'none'],
              lineStyle: {
                type: 'dashed',
                color: '#B2B1C5',
              },
            },
            axisPointer: {
              shadowStyle: {
                color: '#000',
              },
            },
          },
          tooltip: {
            formatter: (v) => {
              const [first] = v;
              const key = this.getTooltip(first.axisValue);
              return key;
            },
          },
          legend: {
            show: false,
          },
        };
        this.datas = {
          columns: ['name', 'value1', 'value2'],
          rows,
        };
      }
    },
    getTooltip(e) {
      let key = '';
      const { list } = this;
      for (let i = 0; i < list.length; i++) {
        let eyeType = list[i].训练眼别;
        if (eyeType === 'OU') {
          eyeType = '双眼';
        } else if (eyeType === 'OD') {
          eyeType = '右眼';
        } else if (eyeType === 'OS') {
          eyeType = '左眼';
        }

        if (this.name === '脱抑制训练—红绿阅读' && list[i].name.slice(5) === e) {
          key = `左眼:${list[i].左眼速度 || 0}字/min<br />右眼:${list[i].右眼速度 || 0}字/min<br />`;
        } else if (
          (this.name === '红闪训练—视标模式'
            || this.name === '光栅训练—视标模式')
          && list[i].name.slice(4) === e
        ) {
          key = `${eyeType}:${list[i].视标最高等级 || '---'}<br />平均辨别:${`${list[i].辨别个数 || list[i].辨别数 || 0}个/min`}`;
        }
      }
      return key;
    },
  },
  data() {
    return {
      barSettings: {
        type: 'bar',
      },
      barExtend: {},
      datas: {},
      yAxis: {},
    };
  },
};
</script>

<style lang="less" scoped>
</style>
