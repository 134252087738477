<template>
  <div>
    <ve-histogram
      :settings="barSettings"
      :height="height"
      :width="width"
      :extend="barExtend"
      :data="datas"
    />
  </div>
</template>

<script>
import VeHistogram from 'v-charts/lib/histogram.common';

export default {
  name: 'barVerticalEchart',
  components: {
    VeHistogram,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: () => '',
    },
    hardwareVersion: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      lists: [],
    };
  },
  async created() {
    const { list } = this;
    let lists = [];
    if (this.name == '调节灵活度训练') {
      for (let i = 0; i < list.length; i++) {
        if (list[i].训练类型 !== '情景') {
          lists.push(list[i]);
        }
      }
    } else {
      lists = list;
    }
    this.lists = list;
    this.getData(lists);
  },
  methods: {
    getData(list) {
      const { name } = this;
      if (name == '调节幅度训练') {
        this.width = '360px';
        this.height = '300px';
        const data = [];
        const rows = [];
        for (let i = 0; i < list.length; i++) {
          data.push(list[i].name.slice(6));
          rows.push({
            name: list[i].训练眼别,
            value: this.convertNewHardwareData(list[i].最高度数),
          });
        }

        let yAxisData = [
          '+2.50',
          '+1.50',
          '0',
          '-1.50',
          '-2.50',
          '-3.50',
          '-4.50',
          '-5.50',
        ];

        yAxisData = yAxisData.map((yAxis) => this.convertNewHardwareData(yAxis));

        this.barExtend = {
          grid: {
            top: '5%',
            left: '-2%',
            bottom: '4%',
            right: '20%',
          },
          xAxis: {
            name: '训练项目',
            nameLocation: 'end',
            nameTextStyle: {
              color: '#000',
            },
            data,
            type: 'category',
            position: 'bottom',
            show: true,
            axisLabel: {
              position: 'bottom',
              show: true,
              interval: 'auto',
              left: 'center',
              color: '#000',
            },
            axisLine: {
              show: true,
              symbol: ['none', 'none'],
              lineStyle: {
                type: 'dashed',
                color: '#B2B1C5',
              },
            },
            axisPointer: {
              shadowStyle: {
                color: '#000',
              },
            },
          },
          yAxis: {
            type: 'category',
            show: true,
            position: 'left',
            boundaryGap: false,
            data: yAxisData,
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
              },
            },
            axisLabel: {
              formatter(value) {
                return value;
              },
            },
            axisTick: {
              alignWithLabel: true,
            },
          },
          // 每个柱子
          series(v) {
            // 设置柱子的样式
            v.forEach((i) => {
              i.barWidth = 15;
              i.barMinHeight = 2;
              i.label = {
                show: true,
                position: 'top',
                offset: [0, 5],
                formatter: (e) => {
                  let subfix = '';
                  if (list[e.dataIndex].训练眼别 === '双眼' || list[e.dataIndex].训练眼别 === 'OU') {
                    subfix = '双';
                  } else if (list[e.dataIndex].训练眼别 === '右眼' || list[e.dataIndex].训练眼别 === 'OD') {
                    subfix = '右';
                  } else {
                    subfix = '左';
                  }
                  return subfix;
                },
              };
              i.itemStyle = {
                barBorderRadius: [10, 10, 0, 0],
                color: (e) => {
                  let colors = '';
                  if (list[e.dataIndex].训练眼别 === '双眼' || list[e.dataIndex].训练眼别 === 'OU') {
                    colors = '#FCC51F';
                  } else if (list[e.dataIndex].训练眼别 === '右眼' || list[e.dataIndex].训练眼别 === 'OD') {
                    colors = '#434ABC';
                  } else {
                    colors = '#FF6A7A';
                  }
                  return colors;
                },
              };
            });
            return v;
          },
          tooltip: {
            show: true,
            formatter: (v) => {
              const [first] = v;
              const key = this.getTooltip(first.axisValue);
              return key;
            },
          },
          legend: {
            show: false,
          },
        };
        this.datas = {
          columns: ['name', 'value'],
          rows,
        };
      } else if (name == '正片阅读' || name == '负片阅读') {
        this.width = '300px';
        const data = [];
        const rows = [];
        for (let i = 0; i < list.length; i++) {
          if (name == '调节灵活度训练') {
            data.push(list[i].name.slice(7));
          } else {
            data.push(list[i].name.slice(6));
          }
          let number = list[i].周期数;
          if (name == '正片阅读' || name == '负片阅读') {
            number = list[i].阅读速度;
          }
          rows.push({ name: list[i].训练眼别, value: number });
        }
        this.barExtend = {
          grid: {
            top: '5%',
            left: '2%',
            bottom: '4%',
            right: '26%',
          },
          // 每个柱子
          series(v) {
            // 设置柱子的样式
            v.forEach((i) => {
              i.barWidth = 15;
              i.barMinHeight = 2;
              i.label = {
                show: true,
                position: 'top',
                offset: [0, 5],
                formatter: (e) => {
                  let subfix = '';
                  if (list[e.dataIndex].训练眼别 === '双眼' || list[e.dataIndex].训练眼别 === 'OU') {
                    subfix = '双';
                  } else if (list[e.dataIndex].训练眼别 === '右眼' || list[e.dataIndex].训练眼别 === 'OD') {
                    subfix = '右';
                  } else {
                    subfix = '左';
                  }
                  return subfix;
                },
              };
              i.itemStyle = {
                barBorderRadius: [10, 10, 0, 0],
                color: (e) => {
                  let colors = '';
                  if (list[e.dataIndex].训练眼别 === '双眼' || list[e.dataIndex].训练眼别 === 'OU') {
                    colors = '#FCC51F';
                  } else if (list[e.dataIndex].训练眼别 === '右眼' || list[e.dataIndex].训练眼别 === 'OD') {
                    colors = '#434ABC';
                  } else {
                    colors = '#FF6A7A';
                  }
                  return colors;
                },
              };
            });
            return v;
          },
          xAxis: {
            name: '训练项目',
            nameLocation: 'end',
            nameTextStyle: {
              color: '#000',
            },
            type: 'category',
            position: 'bottom',
            show: true,
            data,
            axisLabel: {
              position: 'bottom',
              show: true,
              interval: 'auto',
              left: 'center',
              color: '#000',
            },
            axisLine: {
              show: true,
              symbol: ['none', 'none'],
              lineStyle: {
                type: 'dashed',
                color: '#B2B1C5',
              },
            },
            axisPointer: {
              shadowStyle: {
                color: '#000',
              },
            },
          },
          yAxis: {
            type: 'value',
            show: true,
            position: 'left',
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
              },
            },
          },
          tooltip: {
            formatter: (v) => {
              const [first] = v;
              const key = this.getTooltip(first.axisValue);
              return key;
            },
          },
          legend: {
            show: false,
          },
        };
        this.datas = {
          columns: ['name', 'value'],
          rows,
        };
      } else if (name == '调节灵活度训练') {
        this.width = '300px';
        const data = [];
        const rows = [];
        for (let i = 0; i < list.length; i++) {
          if (list[i].训练类型 !== '情景') {
            data.push(list[i].name.slice(7));
            rows.push({
              name: list[i].训练眼别,
              value1: list[i].周期数,
              value2: list[i].周期数250,
            });
          }
        }
        this.barExtend = {
          grid: {
            top: '5%',
            left: '2%',
            bottom: '4%',
            right: '6%',
          },
          // 每个柱子
          series(v) {
            // 设置柱子的样式
            v.forEach((i) => {
              i.barWidth = 15;
              i.barMinHeight = 2;
              i.label = {
                show: true,
                position: 'top',
                offset: [0, 5],
                formatter: (e) => {
                  let subfix = '';
                  if (list[e.dataIndex].训练眼别 === '双眼' || list[e.dataIndex].训练眼别 === 'OU') {
                    subfix = '双';
                  } else if (list[e.dataIndex].训练眼别 === '右眼' || list[e.dataIndex].训练眼别 === 'OD') {
                    subfix = '右';
                  } else {
                    subfix = '左';
                  }
                  return subfix;
                },
              };
              i.itemStyle = {
                barBorderRadius: [10, 10, 0, 0],
                color: (e) => {
                  let colors = '';
                  if (list[e.dataIndex].训练眼别 === '双眼' || list[e.dataIndex].训练眼别 === 'OU') {
                    if (e.seriesName === 'value1') {
                      colors = '#FCC51F';
                    } else {
                      colors = '#EB903E';
                    }
                  } else if (list[e.dataIndex].训练眼别 === '右眼' || list[e.dataIndex].训练眼别 === 'OD') {
                    if (e.seriesName === 'value1') {
                      colors = '#434ABC';
                    } else {
                      colors = '#7070F5';
                    }
                  } else if (e.seriesName === 'value1') {
                    colors = '#FF6A7A';
                  } else {
                    colors = '#F097A6';
                  }
                  return colors;
                },
              };
            });
            return v;
          },
          xAxis: {
            name: '',
            nameLocation: 'end',
            nameTextStyle: {
              color: '#000',
            },
            type: 'category',
            position: 'bottom',
            show: true,
            data,
            axisLabel: {
              position: 'bottom',
              show: true,
              interval: 'auto',
              left: 'center',
              color: '#000',
            },
            axisLine: {
              show: true,
              symbol: ['none', 'none'],
              lineStyle: {
                type: 'dashed',
                color: '#B2B1C5',
              },
            },
            axisPointer: {
              shadowStyle: {
                color: '#000',
              },
            },
          },
          yAxis: {
            type: 'value',
            show: true,
            position: 'left',
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
              },
            },
          },
          tooltip: {
            formatter: (v) => {
              const [first] = v;
              const key = this.getTooltip(first.axisValue);
              return key;
            },
          },
          legend: {
            show: false,
          },
        };
        this.datas = {
          columns: ['name', 'value1', 'value2'],
          rows,
        };
      }
    },
    getTooltip(e) {
      let key = '';
      const list = this.lists;
      for (let i = 0; i < list.length; i++) {
        let eyeType = list[i].训练眼别;
        if (eyeType === 'OU') {
          eyeType = '双眼';
        } else if (eyeType === 'OD') {
          eyeType = '右眼';
        } else if (eyeType === 'OS') {
          eyeType = '左眼';
        }

        if (this.name === '调节灵活度训练' && list[i].name.slice(7) === e) {
          key = `${eyeType}±1.50cpm:${list[i].周期数 || '--'}<br />${
            eyeType
          }±2.50cpm:${list[i].周期数250 || '--'}<br />视标难度:${
            list[i].视标难度 || '---'
          }`;
          key = this.convertNewHardwareData(key);
        } else if (
          (this.name === '正片阅读' || this.name === '负片阅读')
          && list[i].name.slice(6) === e
        ) {
          key = `${eyeType}:${
            list[i].阅读速度 || 0
          }个/min<br />视标难度: ${list[i].视标难度 || '---'}`;
        } else if (this.name === '调节幅度训练' && list[i].name.slice(6) === e) {
          key = `${eyeType}:${list[i].最高度数}<br />视标难度:${
            list[i].视标难度 || '---'
          }`;
          key = this.convertNewHardwareData(key);
        }
      }
      return key;
    },

    convertNewHardwareData(content) {
      let result = content;
      const { hardwareVersion } = this;
      if (hardwareVersion && hardwareVersion !== '1.0') {
        result = content.replace(/2.50/, '2.00').replace(/1.50/, '1.00');
      }

      return result;
    },
  },
  data() {
    return {
      width: '300px',
      height: '300px',
      barSettings: {
        type: 'bar',
      },
      barExtend: {},
    };
  },
};
</script>

<style lang="less" scoped>
</style>
