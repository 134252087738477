<template>
  <div class="pie_echart" :height="pieHeight">
    <div v-if="this.name == '视觉专注训练—追随训练'" class="title">
      {{ this.info.name + "正确率" }}
    </div>
    <div v-else-if="this.name == '精细目力训练'" class="title">
      {{ this.info.name.slice(6) + "正确率" }}
    </div>
    <div v-else-if="this.name == '红闪训练—视标模式'" class="title">
      {{ this.info.name.slice(4) + "正确率" }}
    </div>
    <div v-else-if="this.name == '光栅训练—视标模式'" class="title">
      {{ this.info.name.slice(4) + "正确率" }}
    </div>
    <div v-else-if="this.name == '脱抑制训练—捕捉训练'" class="title">
      {{ this.info.name.slice(5) + "正确率" }}
    </div>
    <div v-else-if="this.name == '镜片阅读训练'" class="title">
      {{ this.info.name.slice(6) + "错误率" }}
    </div>
    <div v-else-if="this.name == '调节灵活度训练'" class="title">
      {{ this.info.name.slice(7) + "-镜片通过率" }}
    </div>
    <div v-else></div>
    <div v-if="this.getType()" class="item-row">
      <div class="point1" :style="{ backgroundColor: this.color }" />
      <div class="right">正确</div>
      <div class="error">错误</div>
      <div class="point2" />
    </div>
    <div
      v-else-if="this.name == '镜片阅读训练' && this.info.训练眼别 != '双眼'"
      class="item-row"
      style="margin-bottom: 24px"
    >
      <div class="point1" style="background-color: #e4e6e9" />
      <div class="right">正确</div>
      <div class="error">错误</div>
      <div class="point2" :style="{ backgroundColor: this.color }" />
    </div>
    <div
      v-else-if="this.name == '镜片阅读训练' && this.info.训练眼别 == '双眼'"
    >
      <div class="item-row">
        <div class="point1" style="background-color: #e4e6e9" />
        <div class="right">正确</div>
        <div class="error">正确</div>
        <div class="point2" style="background-color: #e4e6e9" />
      </div>
      <div class="item-row" style="margin-top: 0px">
        <div class="point1" style="background-color: #f14e60" />
        <div class="right">错误</div>
        <div class="error">错误</div>
        <div class="point2" style="backgroundcolor: #3e75ff" />
      </div>
    </div>
    <div v-if="this.name == '调节灵活度训练'" class="item-row">
      <div class="point1" :style="{ backgroundColor: this.colors[0] }" />
      <div class="right">正片</div>
      <div class="error">负片</div>
      <div class="point2" :style="{ backgroundColor: this.colors[1] }" />
    </div>
    <div
      v-else-if="this.name == '红绿阅读通过比'"
      style="width: 100%; height: 34px"
    ></div>
    <div v-else></div>
    <ve-pie
      :height="height"
      :width="width"
      class="pie"
      :settings="pieSettings"
      :extend="pieExtend"
      :data="data"
    />
    <div v-if="this.name == '红闪训练—视标模式'" class="eye-type">
      {{ this.info.训练眼别 }}
    </div>
    <div v-else-if="this.name == '光栅训练—视标模式'" class="eye-type">
      {{ this.info.训练眼别 }}
    </div>
    <div v-else-if="this.name == '调节灵活度训练'" class="eye-type">
      {{ this.info.训练眼别 }}
    </div>
    <div v-else-if="this.name == '镜片阅读训练'" class="eye-type">
      {{ this.info.训练眼别 }}-{{
        this.info.镜片类型 == "正片阅读" ? "正片" : "负片"
      }}
    </div>
    <div v-else-if="this.name == '红绿阅读左眼'" class="eye-type">左眼</div>
    <div v-else-if="this.name == '红绿阅读右眼'" class="eye-type">右眼</div>
    <div v-else-if="this.name == '红绿阅读通过比'" class="eye-type">
      左右眼通过比
    </div>
    <div v-else></div>
    <div v-if="this.name == '调节灵活度训练'" class="percent">
      正负通过比 {{ this.info.正负通过比 }}
    </div>
  </div>
</template>

<script>
import VePie from "v-charts/lib/pie.common";

export default {
  name: "pieEchart",
  components: {
    VePie,
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: () => "",
    },
  },
  async created() {
    this.getData();
  },
  methods: {
    getData() {
      let name = this.name;
      let info = this.info;
      if (
        this.getType() ||
        (name == "镜片阅读训练" && info.训练眼别 != "双眼")
      ) {
        this.pieHeight = "160px";
        let number = null;
        if (
          name == "视觉专注训练—追随训练" ||
          name == "精细目力训练" ||
          name == "脱抑制训练—捕捉训练"
        ) {
          number = info.正确率;
        }
        if (name == "红闪训练—视标模式" || name == "光栅训练—视标模式") {
          number = info.分辨正确率;
        }
        if (name == "红绿阅读左眼") {
          number = info.左眼正确率;
        }
        if (name == "红绿阅读右眼") {
          number = info.右眼正确率;
        }
        if (name == "镜片阅读训练") {
          number = info.错误率;
        }
        this.pieExtend = {
          color: [this.color, "#e4e6e9"],
          legend: {
            show: false,
          },
          series: {
            type: "pie",
            radius: ["66%", "90%"],
            center: ["50%", "36%"],
            color: [this.color, "#e4e6e9"],
            hoverAnimation: false,
            top: 10,
            legend: {
              show: false,
            },
            tooltip: {
              show: true,
              formatter: (v) => {
                let key = this.getTooltip(number);
                return key;
              },
            },
            label: {
              show: true,
              position: "center",
              color: this.color,
              formatter: (v) => {
                let key = this.getLabel(number);
                return key;
              },
            },
            labelLine: {
              show: false,
            },
          },
        };
        this.data = {
          columns: ["name", "value"],
          rows: [
            { name: "正确率", value: number ? number : 0 },
            { name: "错误率", value: number ? 100 - number : 100 },
          ],
        };
      } else if (name == "红绿阅读通过比") {
        this.pieHeight = "160px";
        this.pieExtend = {
          color: ["#434ABC", "#FF6A7A"],
          legend: {
            show: false,
          },
          series: {
            type: "pie",
            radius: ["66%", "90%"],
            center: ["50%", "36%"],
            hoverAnimation: false,
            top: 10,
            legend: {
              show: false,
            },
            tooltip: {
              show: false,
            },
            label: {
              show: true,
              position: "center",
              color: this.color,
              fontSize: 16,
              formatter: (v) => {
                return `${this.info.左右眼通过比}`;
              },
            },
            labelLine: {
              show: false,
            },
          },
        };
        this.data = {
          columns: ["name", "value"],
          rows: [
            { name: "右眼正确率", value: this.info.左右眼通过比.split(':')[1] || 0 },
            { name: "左眼正确率", value: this.info.左右眼通过比.split(':')[0] || 0 },
          ],
        };
      } else if (name == "调节灵活度训练") {
        this.pieHeight = "200px";
        this.pieExtend = {
          color: [
            "#fff",
            "#E4E6E9",
            this.colors[1],
            "#fff",
            this.colors[0],
            "#E4E6E9",
            "#fff",
          ],
          legend: {
            show: false,
          },
          series: {
            type: "pie",
            radius: ["50%", "75%"],
            center: ["49%", "36%"],
            hoverAnimation: false,
            top: 10,
            legend: {
              show: false,
            },
            tooltip: {
              show: false,
            },
            label: {
              show: true,
              position: "outside",
              formatter: (v) => {
                return v.data.name == "负通过率"
                  ? `{a|负片\n通过率\n${this.info.负通过率}%}`
                  : v.data.name == "正通过率"
                  ? `{b|正片\n通过率\n${this.info.正通过率}%}`
                  : "";
              },
              rich: {
                a: {
                  color: this.colors[1],
                  fontSize: "12px",
                },
                b: {
                  color: this.colors[0],
                  fontSize: "12px",
                },
              },
            },
            labelLine: {
              show: false,
              length: '4px'
            },
          },
        };
        this.data = {
          columns: ["name", "value"],
          rows: [
            { name: "", value: 2 },
            { name: "", value: 100 - this.info.负通过率 },
            { name: "负通过率", value: this.info.负通过率 },
            { name: "", value: 4 },
            { name: "正通过率", value: this.info.正通过率 },
            { name: "", value: 100 - this.info.正通过率 },
            { name: "", value: 2 },
          ],
        };
      } else if (name == "镜片阅读训练" && info.训练眼别 == "双眼") {
        this.pieHeight = "200px";
        this.pieExtend = {
          color: [
            "#fff",
            "#E4E6E9",
            "#3E75FF",
            "#fff",
            "#F14E60",
            "#E4E6E9",
            "#fff",
          ],
          legend: {
            show: false,
          },
          series: {
            type: "pie",
            radius: ["66%", "90%"],
            center: ["50%", "36%"],
            hoverAnimation: false,
            top: 10,
            legend: {
              show: false,
            },
            tooltip: {
              show: true,
              formatter: (v) => {
                let key = this.getTooltip(this.info.错误率);
                return key;
              },
            },
            label: {
              show: true,
              position: "center",
              color: this.colors[0],
              fontSize: "12px",
              formatter: (v) => {
                return `错误率\n${this.info.错误率}%`;
              },
            },
            labelLine: {
              show: false,
            },
          },
        };
        this.data = {
          columns: ["name", "value"],
          rows: [
            { name: "", value: 2 },
            { name: "", value: 100 - this.info.错误率 },
            { name: "负通过率", value: this.info.错误率 },
            { name: "", value: 4 },
            { name: "正通过率", value: this.info.错误率 },
            { name: "", value: 100 - this.info.错误率 },
            { name: "", value: 2 },
          ],
        };
      }
    },
    getType() {
      let name = false;
      if (
        this.name == "视觉专注训练—追随训练" ||
        this.name == "精细目力训练" ||
        this.name == "红闪训练—视标模式" ||
        this.name == "光栅训练—视标模式" ||
        this.name == "脱抑制训练—捕捉训练" ||
        this.name == "红绿阅读左眼" ||
        this.name == "红绿阅读右眼"
      ) {
        name = true;
      }
      if (this.name == "红绿阅读左眼") {
        this.color = "#FF6A7A";
      }
      if (this.name == "红绿阅读右眼") {
        this.color = "#434ABC";
      }
      if (
        this.name == "红闪训练—视标模式" ||
        this.name == "光栅训练—视标模式" ||
        this.name == "镜片阅读训练"
      ) {
        if (this.info.训练眼别 == "左眼") {
          this.color = "#FF6A7A";
        } else if (this.info.训练眼别 == "右眼") {
          this.color = "#434ABC";
        } else {
          this.color = "#FCC51F";
        }
      }
      if (this.name == "调节灵活度训练") {
        if (this.info.训练眼别 == "左眼") {
          this.colors = ["#F14E60", "#FF94A6"];
        } else if (this.info.训练眼别 == "右眼") {
          this.colors = ["#3E75FF", "#75CCF0"];
        } else {
          this.colors = ["#FC8C1F", "#FFC323"];
        }
      }
      return name;
    },
    getLabel(number) {
      let key = `正确率\n${number}%`;
      if (this.name == "镜片阅读训练") {
        key = `错误率\n${number}%`;
      }
      if (this.name == "脱抑制训练—捕捉训练") {
        key = `正确率\n${this.info.正确率}%\n${this.info.捕捉数}个/min`;
      }
      return key;
    },
    getTooltip(number) {
      let key = "";
      if (this.name == "镜片阅读训练") {
        key = `错误率:${number}%<br />视标等级:${this.info.视标难度 || "---"}`;
      }
      if (
        this.name == "光栅训练—视标模式" ||
        this.name == "红闪训练—视标模式"
      ) {
        key = `错误率:${number}%<br />视标等级:${
          this.info.视标最高等级 || "---"
        }`;
      }
      return key;
    },
  },
  data() {
    return {
      pieSettings: {
        type: "pie",
      },
      height: "120px",
      width: "200px",
      pieHeight: "160px",
      color: "#7a71f2",
      colors: ["#3E75FF", "#75CCF0"],
      pieExtend: {},
      data: {},
    };
  },
};
</script>

<style lang="less" scoped>
.pie_echart {
  width: 100%;

  .title {
    width: 140px;
    height: 24px;
    text-align: center;
    background: #f2f4f6;
    font-size: 12px;
    line-height: 24px;
    margin-left: auto;
    margin-right: auto;
  }

  .item-row {
    width: 100%;
    height: 24px;
    margin-top: 10px;

    .point1 {
      float: left;
      width: 6px;
      height: 6px;
      margin-left: 55px;
      margin-top: 9px;
      border-radius: 50%;
    }

    .right {
      float: left;
      margin-left: 2px;
      font-size: 6px;
      line-height: 24px;
    }

    .point2 {
      float: right;
      width: 6px;
      height: 6px;
      margin-right: 2px;
      margin-top: 9px;
      background: #e4e6e9;
      border-radius: 50%;
    }

    .error {
      float: right;
      margin-right: 55px;
      font-size: 6px;
      line-height: 24px;
    }
  }
  .pie {
    margin-left: auto;
    margin-right: auto;
  }

  .eye-type {
    width: 100%;
    text-align: center;
    font-size: 8px;
    font-weight: 500;
    transform: translateY(-14px);
  }

  .percent {
    width: 130px;
    height: 22px;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    background: #e4e6e9;
    border-radius: 35px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
  }
}
</style>