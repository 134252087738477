<template>
  <!--startprint-->
  <div class="item-head">
    <div class="table">
      <div>
        <img v-if="!info.store_name" src="../../../assets/info_logo.png" class="logo" alt="" />
        <Button class="export-btn" id="print" @click="() => doPrint()"
          >打印</Button
        >
        <!-- 表头 -->
        <Row v-if="info.store_name" class="row-head" style="text-align: center;">
          <Col span="24" class="title">{{ info.store_name || "" }}</Col>
        </Row>
        <Row class="row-head">
          <Col span="12" class="title">视功能训练报告</Col>
          <Col span="12" class="score"
            >训练平均得分：
            <Rate
              allow-half
              v-model="info.train_avg_score"
              style="font-size: 20px"
              disabled
          /></Col>
        </Row>
        <Row class="row-table" style="margin-top: 34px">
          <Col span="6">姓名：{{ info.nick_name }}</Col>
          <Col span="6">性别：{{ info.sex }}</Col>
          <Col span="6">年龄：{{ info.age }}</Col>
          <Col span="6">电话：{{ info.mobile }}</Col>
        </Row>
        <Row class="row-table">
          <Col span="6">总时长：{{ info.train_timelen }}</Col>
          <Col span="6">完成项目：{{ info.train_number }}项</Col>
          <Col span="6">方案：{{ info.train_title }}</Col>
          <Col span="6">地点：{{ info.store_name || "自主训练" }}</Col>
        </Row>
        <Row class="row-table">
          <Col span="12">开始时间：{{ info.start_time || "" }}</Col>
          <Col span="12">结束时间：{{ info.end_time || "暂未结束" }}</Col>
        </Row>
      </div>
      <!-- 单项训练报告 -->
      <Row
        class="item-wrapper"
        v-for="(item, index) in histogramChartDatas"
        :key="index"
        :style="{ height: 'auto' }"
      >
        <!-- 标题 -->
        <div class="info-head">
          <span class="info-title">{{ item.title }}</span>
          <span class="info-titles">{{ getNameTitle(item, item.title) }}</span>
        </div>
        <!-- 内容 -->
        <Row
          v-if="item.title == '调节幅度训练'"
          style="width: 100%; margin-top: 8px"
        >
          <Col span="12">
            <div class="left-name" style="width: 100px">通过最高度数</div>
            <Row style="margin-top: 12px">
              <Col span="12" style="font-size: 12px; padding-left: 20px"
                >通过最高度数</Col
              >
              <Col span="12" style="font-size: 9px">
                <div class="eye-color" style="background-color: #fcc51f" />
                <div class="eys-type">双眼</div>
                <div class="eye-color" style="background-color: #434abc" />
                <div class="eys-type">右眼</div>
                <div class="eye-color" style="background-color: #ff6a7a" />
                <div class="eys-type">左眼</div>
              </Col>
            </Row>
            <bar-vertical-echart :list="item.lists" :name="item.title" :hardwareVersion="info.hardware_version" />
          </Col>
          <Col span="12">
            <div class="item-table">
              <Row class="table-head">
                <Col span="7">训练项目</Col>
                <Col span="7">通过的最高度数</Col>
                <Col span="5">眼别</Col>
                <Col span="5">视标难度</Col>
              </Row>
              <Row
                v-for="(e, index) in item.lists"
                :key="index"
                :style="{
                  backgroundColor: index % 2 != 1 ? '#fff' : '#F8F8F9',
                }"
                class="table-row"
              >
                <Col span="7">{{ e.name }}</Col>
                <Col span="7">{{ convertNewHardwareData(e.最高度数) }}</Col>
                <Col span="5">{{ convertEyeType(e.训练眼别) }}</Col>
                <Col span="5">{{ e.视标难度 || "---" }}</Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row
          v-else-if="item.title == '调节灵活度训练'"
          style="width: 100%; margin-top: 8px"
        >
          <Col span="10">
            <div class="left-name" style="width: 144px">
              镜片通过每分钟周期数
            </div>
            <Row style="margin-top: 12px">
              <Col span="6" style="font-size: 12px; padding-left: 20px"
                >cpm</Col
              >
              <Col span="6" style="font-size: 9px">
                <div class="eye-color" style="background-color: #fcc51f" />
                <div class="eys-type">{{convertNewHardwareData('双眼1.50')}}</div>
              </Col>
              <Col span="6" style="font-size: 9px">
                <div class="eye-color" style="background-color: #434abc" />
                <div class="eys-type">{{convertNewHardwareData('右眼1.50')}}</div>
              </Col>
              <Col span="6" style="font-size: 9px">
                <div class="eye-color" style="background-color: #ff6a7a" />
                <div class="eys-type">{{convertNewHardwareData('左眼1.50')}}</div>
              </Col>
            </Row>
            <Row>
              <Col span="6" style="font-size: 12px; padding-left: 20px"
                >cpm</Col
              >
              <Col span="6" style="font-size: 9px">
                <div class="eye-color" style="background-color: #eb903e" />
                <div class="eys-type">{{convertNewHardwareData('双眼2.50')}}</div>
              </Col>
              <Col span="6" style="font-size: 9px">
                <div class="eye-color" style="background-color: #7070f5" />
                <div class="eys-type">{{convertNewHardwareData('右眼2.50')}}</div>
              </Col>
              <Col span="6" style="font-size: 9px">
                <div class="eye-color" style="background-color: #f097a6" />
                <div class="eys-type">{{convertNewHardwareData('左眼2.50')}}</div>
              </Col>
            </Row>
            <bar-vertical-echart :list="item.lists" :name="item.title" :hardwareVersion="info.hardware_version"/>
          </Col>
          <Col span="14">
            <div class="item-table">
              <Row class="table-head">
                <Col span="4">训练项目</Col>
                <Col span="5">镜片每分钟通过周期数</Col>
                <Col span="5">正片通过率</Col>
                <Col span="4">负片通过率</Col>
                <Col span="3">眼别</Col>
                <Col span="3">视标难度</Col>
              </Row>
              <Row
                v-for="(e, index) in item.lists"
                :key="index"
                :style="{
                  backgroundColor: index % 2 != 1 ? '#fff' : '#F8F8F9',
                }"
                class="table-row"
              >
                <Col span="4">{{ e.name }}</Col>
                <Col span="5">
                  <div v-if="e.训练类型 == '情景'">
                    <div>--</div>
                  </div>
                  <div v-else>
                    <div>{{convertNewHardwareData('±1.50:')}} {{ e.周期数 || "--" }}</div>
                    <div>{{convertNewHardwareData('±2.50:')}} {{ e.周期数250 || "--" }}</div>
                  </div>
                </Col>
                <Col span="5">
                  <div>{{convertNewHardwareData('1.50:')}} {{ e.正通过率 || 0 }}%</div>
                  <div>{{convertNewHardwareData('2.50:')}} {{ e.正通过率250 || 0 }}%</div>
                </Col>
                <Col span="4">
                  <div>{{convertNewHardwareData('1.50:')}} {{ e.负通过率 || 0 }}%</div>
                  <div>{{convertNewHardwareData('2.50:')}} {{ e.负通过率250 || 0 }}%</div>
                </Col>
                <Col span="3">{{ convertEyeType(e.训练眼别) || "---" }}</Col>
                <Col span="3">{{ e.视标难度 || "---" }}</Col>
              </Row>
            </div>
          </Col>
          <!-- <div class="table_line">
            -----------------------------------------------------------------------
          </div>
          <div style="width: 100%">
            <Row style="width: 100%">
              <Col
                v-for="(e, index) in item.lists"
                :key="index"
                span="8"
                style="margin-bottom: 30px"
              >
                <pie-echart :info="e" :name="item.title" />
              </Col>
            </Row>
          </div> -->
        </Row>
        <Row
          v-else-if="item.title == '镜片阅读训练'"
          style="width: 100%; margin-top: 8px"
        >
          <div
            :style="{
              display: readList.length > 0 ? 'block' : 'none',
              width: '100%',
            }"
          >
            <Row style="width: 100%">
              <Col span="10">
                <div class="left-name" style="width: 134px">
                  正片阅读-阅读速度
                </div>
                <Row style="margin-top: 12px">
                  <Col span="12" style="font-size: 12px; padding-left: 20px"
                    >阅读速度</Col
                  >
                  <Col span="12" style="font-size: 9px">
                    <div class="eye-color" style="background-color: #fcc51f" />
                    <div class="eys-type">双眼</div>
                    <div class="eye-color" style="background-color: #434abc" />
                    <div class="eys-type">右眼</div>
                    <div class="eye-color" style="background-color: #ff6a7a" />
                    <div class="eys-type">左眼</div>
                  </Col>
                </Row>
                <bar-vertical-echart :list="readList" name="正片阅读" />
              </Col>
              <Col span="14">
                <div class="item-table">
                  <Row class="table-head">
                    <Col span="6">训练项目</Col>
                    <Col span="6">每分钟正确辨别个数</Col>
                    <Col span="4">眼别</Col>
                    <Col span="4">视标难度</Col>
                    <Col span="4">错误率</Col>
                  </Row>
                  <Row
                    v-for="(e, index) in readList"
                    :key="index"
                    :style="{
                      backgroundColor: index % 2 != 1 ? '#fff' : '#F8F8F9',
                    }"
                    class="table-row"
                  >
                    <Col span="6">{{ e.name }}</Col>
                    <Col span="6">{{ (e.阅读速度 || 0) + "个/min" }}</Col>
                    <Col span="4">{{ convertEyeType(e.训练眼别) }}</Col>
                    <Col span="4">{{ e.视标难度 || "---" }}</Col>
                    <Col span="4">{{ (e.错误率 || 0) + "%" }}</Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          <div
            class="table_line"
            :style="{
              display:
                readList.length > 0 && readLists.length > 0 ? 'block' : 'none',
            }"
          >
            -----------------------------------------------------------------------
          </div>
          <div
            :style="{
              display: readLists.length > 0 ? 'block' : 'none',
              width: '100%',
              marginTop: '10px',
            }"
          >
            <Row style="width: 100%">
              <Col span="10">
                <div class="left-name" style="width: 134px">
                  负片阅读-阅读速度
                </div>
                <Row style="margin-top: 12px">
                  <Col span="12" style="font-size: 12px; padding-left: 20px"
                    >阅读速度</Col
                  >
                  <Col span="12" style="font-size: 9px">
                    <div class="eye-color" style="background-color: #fcc51f" />
                    <div class="eys-type">双眼</div>
                    <div class="eye-color" style="background-color: #434abc" />
                    <div class="eys-type">右眼</div>
                    <div class="eye-color" style="background-color: #ff6a7a" />
                    <div class="eys-type">左眼</div>
                  </Col>
                </Row>
                <bar-vertical-echart :list="readLists" name="负片阅读" />
              </Col>
              <Col span="14">
                <div class="item-table">
                  <Row class="table-head">
                    <Col span="6">训练项目</Col>
                    <Col span="6">每分钟正确辨别个数</Col>
                    <Col span="4">眼别</Col>
                    <Col span="4">视标难度</Col>
                    <Col span="4">错误率</Col>
                  </Row>
                  <Row
                    v-for="(e, index) in readLists"
                    :key="index"
                    :style="{
                      backgroundColor: index % 2 != 1 ? '#fff' : '#F8F8F9',
                    }"
                    class="table-row"
                  >
                    <Col span="6">{{ e.name }}</Col>
                    <Col span="6">{{ (e.阅读速度 || 0) + "个/min" }}</Col>
                    <Col span="4">{{ convertEyeType(e.训练眼别) }}</Col>
                    <Col span="4">{{ e.视标难度 || "---" }}</Col>
                    <Col span="4">{{ (e.错误率 || 0) + "%" }}</Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          <!-- <div class="table_line">
            -----------------------------------------------------------------------
          </div>
          <div style="width: 100%">
            <Row style="width: 100%">
              <Col
                v-for="(e, index) in item.lists"
                :key="index"
                span="8"
                style="margin-bottom: 30px"
              >
                <pie-echart :info="e" :name="item.title" />
              </Col>
            </Row>
          </div> -->
        </Row>
        <Row
          v-else-if="item.title == '融像训练'"
          style="width: 100%; margin-top: 8px"
        >
          <div class="item-table" style="margin-top: 2px">
            <Row class="table-head">
              <Col span="8">训练项目</Col>
              <Col span="8">集合范围</Col>
              <Col span="8">散开范围</Col>
            </Row>
            <Row
              v-for="(e, index) in item.lists"
              :key="index"
              :style="{
                backgroundColor: index % 2 != 1 ? '#fff' : '#F8F8F9',
              }"
              class="table-row"
            >
              <Col span="8">{{ e.name }}</Col>
              <Col span="8" v-if="e.训练项目 == '融像综合训练'">{{
                '0-' + (e.集合范围 || 0) + "▲"
              }}</Col>
              <Col span="8" v-else>{{
                e.集合范围 ? e.集合范围 + " ▲" : "---"
              }}</Col>
              <Col span="8" v-if="e.训练项目 == '融像综合训练'">{{
                '0' + (e.散开范围 || 0) + "▲"
              }}</Col>
              <Col span="8" v-else>{{
                e.散开范围 ? e.散开范围 + " ▲" : "---"
              }}</Col>
            </Row>
          </div>
        </Row>
        <Row
          v-else-if="item.title == '脱抑制训练—红绿阅读'"
          style="width: 100%; margin-top: 8px"
        >
          <Col span="10">
            <div class="left-name" style="width: 70px">阅读速度</div>
            <Row style="margin-top: 12px">
              <Col span="12" style="font-size: 12px; padding-left: 20px"
                >训练项目</Col
              >
              <Col span="12" style="font-size: 9px">
                <div class="eye-color" style="background-color: #fff" />
                <div class="eys-type"></div>
                <div class="eye-color" style="background-color: #434abc" />
                <div class="eys-type">右眼</div>
                <div class="eye-color" style="background-color: #ff6a7a" />
                <div class="eys-type">左眼</div>
              </Col>
            </Row>
            <bar-horizontal-echart :list="item.lists" :name="item.title" />
          </Col>
          <Col span="14">
            <div class="item-table">
              <Row class="table-head">
                <Col span="8">训练项目</Col>
                <Col span="8">眼别</Col>
                <Col span="8">阅读速度</Col>
              </Row>
              <Row
                v-for="(e, index) in item.lists"
                :key="index"
                :style="{
                  backgroundColor: index % 2 != 1 ? '#fff' : '#F8F8F9',
                }"
                class="table-row"
              >
                <Col span="8">{{ e.name }}</Col>
                <Col span="8">
                  <div>左眼</div>
                  <div>右眼</div></Col
                >
                <Col span="8">
                  <div>{{ e.左眼速度 }}字/s</div>
                  <div>{{ e.右眼速度 }}字/s</div></Col
                >
              </Row>
            </div>
          </Col>
          <div class="table_line">
            -----------------------------------------------------------------------
          </div>
          <div style="width: 100%; margin-top: 12px">
            <Row
              style="width: 100%; margin-bottom: 30px"
              v-for="(e, index) in item.lists"
              :key="index"
            >
              <Col span="24">
                <div class="left-name" style="width: 140px">
                  {{ e.name.slice(5) + "正确率" }}
                </div></Col
              >
              <Col span="8">
                <pie-echart :info="e" name="红绿阅读左眼" />
              </Col>
              <Col span="8">
                <pie-echart :info="e" name="红绿阅读右眼" />
              </Col>
              <Col span="8">
                <pie-echart :info="e" name="红绿阅读通过比" />
              </Col>
            </Row>
          </div>
        </Row>
        <Row
          v-else-if="item.title == '脱抑制训练—捕捉训练'"
          style="width: 100%; margin-top: 8px"
        >
          <div style="width: 100%">
            <Row style="width: 100%">
              <Col v-for="(e, index) in item.lists" :key="index" span="8">
                <pie-echart :info="e" :name="item.title" />
              </Col>
            </Row>
          </div>
          <div class="item-table" style="margin-top: 8px">
            <Row class="table-head">
              <Col span="8">训练项目</Col>
              <Col span="8">平均每分钟捕捉</Col>
              <Col span="8">正确率</Col>
            </Row>
            <Row
              v-for="(e, index) in item.lists"
              :key="index"
              :style="{
                backgroundColor: index % 2 != 1 ? '#fff' : '#F8F8F9',
              }"
              class="table-row"
            >
              <Col span="8">{{ e.name }}</Col>
              <Col span="8">{{ (e.捕捉数 || 0) + "个/min" }}</Col>
              <Col span="8">{{ (e.正确率 || 0) + "%" }}</Col>
            </Row>
          </div>
        </Row>
        <Row
          v-else-if="item.title == '红闪训练—找不同'"
          style="width: 100%; margin-top: 8px"
        >
          <div class="item-table" style="margin-top: 2px">
            <Row class="table-head">
              <Col span="12">训练项目</Col>
              <Col span="12">平均每分钟找出个数</Col>
            </Row>
            <Row
              v-for="(e, index) in item.lists"
              :key="index"
              :style="{
                backgroundColor: index % 2 != 1 ? '#fff' : '#F8F8F9',
              }"
              class="table-row"
            >
              <Col span="12">{{ e.name }}</Col>
              <Col span="12">{{ (e.找到个数 || 0) + "个/min" }}</Col>
            </Row>
          </div>
        </Row>
        <Row
          v-else-if="item.title == '红闪训练—漫画故事'"
          style="width: 100%; margin-top: 8px"
        >
          <div class="item-table" style="margin-top: 2px">
            <Row class="table-head">
              <Col span="12">训练项目</Col>
              <Col span="12">阅读页数</Col>
            </Row>
            <Row
              v-for="(e, index) in item.lists"
              :key="index"
              :style="{
                backgroundColor: index % 2 != 1 ? '#fff' : '#F8F8F9',
              }"
              class="table-row"
            >
              <Col span="12">{{ e.name }}</Col>
              <Col span="12">{{ (e.阅读页数 || 0) + "页" }}</Col>
            </Row>
          </div>
        </Row>
        <Row
          v-else-if="item.title == '光栅训练—单词模式'"
          style="width: 100%; margin-top: 8px"
        >
          <div class="item-table" style="margin-top: 2px">
            <Row class="table-head">
              <Col span="12">训练项目</Col>
              <Col span="12">学习单词个数</Col>
            </Row>
            <Row
              v-for="(e, index) in item.lists"
              :key="index"
              :style="{
                backgroundColor: index % 2 != 1 ? '#fff' : '#F8F8F9',
              }"
              class="table-row"
            >
              <Col span="12">{{ e.name }}</Col>
              <Col span="12">{{ (e.单词个数 || 0) + "个" }}</Col>
            </Row>
          </div>
        </Row>
        <Row
          v-else-if="item.title == '光栅训练—色图模式'"
          style="width: 100%; margin-top: 8px"
        >
          <div class="item-table" style="margin-top: 2px">
            <Row class="table-head">
              <Col span="12">训练项目</Col>
              <Col span="12">平均每分钟正确辨别</Col>
            </Row>
            <Row
              v-for="(e, index) in item.lists"
              :key="index"
              :style="{
                backgroundColor: index % 2 != 1 ? '#fff' : '#F8F8F9',
              }"
              class="table-row"
            >
              <Col span="12">{{ e.name }}</Col>
              <Col span="12">{{ (e.辨别个数 || 0) + "个/min" }}</Col>
            </Row>
          </div>
        </Row>
        <Row
          v-else-if="item.title == '精细目力训练'"
          style="width: 100%; margin-top: 8px"
        >
          <div style="width: 100%; margin-top: 2px">
            <Row style="width: 100%">
              <Col v-for="(e, index) in item.lists" :key="index" span="8">
                <pie-echart :info="e" :name="item.title" />
              </Col>
            </Row>
          </div>
          <div class="item-table" style="margin-top: 10px">
            <Row class="table-head">
              <Col span="8">训练项目</Col>
              <Col span="8">单位时间数量</Col>
              <Col span="8">正确率</Col>
            </Row>
            <Row
              v-for="(e, index) in item.lists"
              :key="index"
              :style="{
                backgroundColor: index % 2 != 1 ? '#fff' : '#F8F8F9',
              }"
              class="table-row"
            >
              <Col span="8">{{ e.name }}</Col>
              <Col span="8">{{
                (e.捕捉个数 || e.串珠个数 || 0) + "个/min"
              }}</Col>
              <Col span="8">{{ (e.正确率 || 0) + "%" }}</Col>
            </Row>
          </div>
        </Row>
        <Row
          v-else-if="item.title == '视觉专注训练—追随训练'"
          style="width: 100%; margin-top: 8px"
        >
          <div style="width: 100%; margin-top: 2px">
            <Row style="width: 100%">
              <Col
                v-for="(e, index) in item.lists"
                :key="index"
                span="8"
                style="margin-bottom: 30px"
              >
                <pie-echart
                  v-if="e.训练类型 == '自主追随' && e.等级 == '初级'"
                  :info="e"
                  :name="item.title"
                />
              </Col>
            </Row>
          </div>
          <div class="item-table" style="margin-top: 10px">
            <Row class="table-head">
              <Col span="12">训练项目</Col>
              <Col span="12">辨别个数</Col>
            </Row>
            <Row
              v-for="(e, index) in item.lists"
              :key="index"
              :style="{
                backgroundColor: index % 2 != 1 ? '#fff' : '#F8F8F9',
              }"
              class="table-row"
            >
              <Col span="12">{{ e.name }}</Col>
              <Col span="12">{{
                e.训练类型 == "引导追随"
                  ? "完成"
                  : typeof e.辨别个数 === 'string' && e.辨别个数.indexOf('1级') > -1
                  ? e.辨别个数
                  :(e.辨别个数 || 0) + (e.等级 == "高级" ? "个" : "个/min")
              }}</Col>
            </Row>
          </div>
        </Row>
        <Row
          v-else-if="item.title == '眼肌运动训练'"
          style="width: 100%; margin-top: 8px"
        >
          <div class="item-table" style="margin-top: 4px">
            <Row class="table-head">
              <Col span="12">训练项目</Col>
              <Col span="12">40cm处追随速度</Col>
            </Row>
            <Row
              v-for="(e, index) in item.lists"
              :key="index"
              :style="{
                backgroundColor: index % 2 != 1 ? '#fff' : '#F8F8F9',
              }"
              class="table-row"
            >
              <Col span="12">{{ e.name }}</Col>
              <Col span="12">{{ (e.追随速度 || 0) + "cm/s" }}</Col>
            </Row>
          </div>
        </Row>
        <Row
          v-else-if="item.title == '视觉专注训练—反差阅读'"
          style="width: 100%; margin-top: 8px"
        >
          <div class="item-table" style="margin-top: 2px">
            <Row class="table-head">
              <Col span="12">训练项目</Col>
              <Col span="12">本次阅读页数</Col>
            </Row>
            <Row
              v-for="(e, index) in item.lists"
              :key="index"
              :style="{
                backgroundColor: index % 2 != 1 ? '#fff' : '#F8F8F9',
              }"
              class="table-row"
            >
              <Col span="12">{{ e.name }}</Col>
              <Col span="12">{{ (e.阅读页数 || 0) + "页" }}</Col>
            </Row>
          </div>
        </Row>
        <Row
          v-else-if="item.title == '视觉专注训练—找不同'"
          style="width: 100%; margin-top: 8px"
        >
          <div class="item-table" style="margin-top: 2px">
            <Row class="table-head">
              <Col span="12">训练项目</Col>
              <Col span="12">平均每分钟找出个数</Col>
            </Row>
            <Row
              v-for="(e, index) in item.lists"
              :key="index"
              :style="{
                backgroundColor: index % 2 != 1 ? '#fff' : '#F8F8F9',
              }"
              class="table-row"
            >
              <Col span="12">{{ e.name }}</Col>
              <Col span="12">{{ (e.找到个数 || 0) + "个/min" }}</Col>
            </Row>
          </div>
        </Row>
        <Row
          v-else-if="item.title == '扫视训练'"
          style="width: 100%; margin-top: 8px"
        >
          <div class="item-table" style="margin-top: 2px">
            <Row class="table-head">
              <Col span="12">训练项目</Col>
              <Col span="12">内容</Col>
            </Row>
            <Row
              v-for="(e, index) in item.lists"
              :key="index"
              :style="{
                backgroundColor: index % 2 != 1 ? '#fff' : '#F8F8F9',
              }"
              class="table-row"
            >
              <Col span="12">{{ e.name }}</Col>
              <Col span="12">{{ getContent(e) }}</Col>
            </Row>
          </div>
        </Row>
        <Row
          v-else-if="item.title == '红闪训练—视标模式'"
          style="width: 100%; margin-top: 8px"
        >
          <Col span="10">
            <div class="left-name" style="width: 140px">通过的最高视标等级</div>
            <Row style="margin-top: 12px">
              <Col span="12" style="font-size: 12px; padding-left: 20px"
                >训练项目</Col
              >
              <Col span="12" style="font-size: 9px">
                <div class="eye-color" style="background-color: #fcc51f" />
                <div class="eys-type">双眼</div>
                <div class="eye-color" style="background-color: #434abc" />
                <div class="eys-type">右眼</div>
                <div class="eye-color" style="background-color: #ff6a7a" />
                <div class="eys-type">左眼</div>
              </Col>
            </Row>
            <bar-horizontal-echart :list="item.lists" :name="item.title" />
          </Col>
          <Col span="14">
            <div class="item-table">
              <Row class="table-head">
                <Col span="5">训练项目</Col>
                <Col span="4">眼别</Col>
                <Col span="5">每分钟辨别</Col>
                <Col span="5">通过的最高视标等级</Col>
                <Col span="5">分辨正确率</Col>
              </Row>
              <Row
                v-for="(e, index) in item.lists"
                :key="index"
                :style="{
                  backgroundColor: index % 2 != 1 ? '#fff' : '#F8F8F9',
                }"
                class="table-row"
              >
                <Col span="5">{{ e.name }}</Col>
                <Col span="4">{{ convertEyeType(e.训练眼别) }}</Col>
                <Col span="5">{{ (e.辨别数 || 0) + "个/min" }}</Col>
                <Col span="5">{{ e.视标最高等级 || "---" }}</Col>
                <Col span="5">{{ (e.分辨正确率 || 0) + "%" }}</Col>
              </Row>
            </div>
          </Col>
          <div class="table_line">
            -----------------------------------------------------------------------
          </div>
          <div style="width: 100%">
            <Row style="width: 100%">
              <Col
                v-for="(e, index) in item.lists"
                :key="index"
                span="8"
                style="margin-bottom: 30px"
              >
                <pie-echart :info="e" :name="item.title" />
              </Col>
            </Row>
          </div>
        </Row>
        <Row
          v-else-if="item.title == '光栅训练—视标模式'"
          style="width: 100%; margin-top: 8px"
        >
          <Col span="10">
            <div class="left-name" style="width: 140px">通过的最高视标等级</div>
            <Row style="margin-top: 12px">
              <Col span="12" style="font-size: 12px; padding-left: 20px"
                >训练项目</Col
              >
              <Col span="12" style="font-size: 9px">
                <div class="eye-color" style="background-color: #fcc51f" />
                <div class="eys-type">双眼</div>
                <div class="eye-color" style="background-color: #434abc" />
                <div class="eys-type">右眼</div>
                <div class="eye-color" style="background-color: #ff6a7a" />
                <div class="eys-type">左眼</div>
              </Col>
            </Row>
            <bar-horizontal-echart :list="item.lists" :name="item.title" />
          </Col>
          <Col span="14">
            <div class="item-table">
              <Row class="table-head">
                <Col span="5">训练项目</Col>
                <Col span="4">眼别</Col>
                <Col span="5">每分钟辨别</Col>
                <Col span="5">通过的最高视标等级</Col>
                <Col span="5">分辨正确率</Col>
              </Row>
              <Row
                v-for="(e, index) in item.lists"
                :key="index"
                :style="{
                  backgroundColor: index % 2 != 1 ? '#fff' : '#F8F8F9',
                }"
                class="table-row"
              >
                <Col span="5">{{ e.name }}</Col>
                <Col span="4">{{ convertEyeType(e.训练眼别) }}</Col>
                <Col span="5">{{
                  (e.辨别数 || e.辨别个数 || 0) + "个/min"
                }}</Col>
                <Col span="5">{{ e.视标最高等级 || "---" }}</Col>
                <Col span="5">{{ (e.分辨正确率 || 0) + "%" }}</Col>
              </Row>
            </div>
          </Col>
          <div class="table_line">
            -----------------------------------------------------------------------
          </div>
          <div style="width: 100%">
            <Row style="width: 100%">
              <Col
                v-for="(e, index) in item.lists"
                :key="index"
                span="8"
                style="margin-bottom: 30px"
              >
                <pie-echart :info="e" :name="item.title" />
              </Col>
            </Row>
          </div>
        </Row>
        <Row v-else></Row>
      </Row>
      <div class="end-line" />
      <Row style="width: 100%; height: 30px">
        <Col span="12" style="padding-left: 40px">
          <img class="img-icon1" src="../../../assets/report_info_name.png" />
          <div class="bottom-name">检查者签字：</div>
          <div class="bottom-line" />
        </Col>
        <Col span="12" style="padding-left: 40px">
          <img class="img-icon1" src="../../../assets/report_info_date.png" />
          <div class="bottom-name">日期：</div>
          <div class="bottom-line"
        /></Col>
      </Row>
      <!-- <div class="company-name">快视（天津）科技有限公司</div> -->
      <div class="company-name">SNAPSCOPE</div>
    </div>
  </div>
  <!--endprint-->
</template>

<script>
import pieEchart from './pies.component.vue';
import barVerticalEchart from './bar-vertical.component.vue';
import barHorizontalEchart from './bar-horizontal.component.vue';
import TrainingReportApi from './training-report.api';

export default {
  name: 'report-info',
  components: {
    pieEchart,
    barVerticalEchart,
    barHorizontalEchart,
  },
  model: {
    prop: 'visible',
    event: 'on-close',
  },
  async created() {
    this.info = await TrainingReportApi.getReportDetail(this.$route.query.id);
    this.processData();
  },
  data() {
    return {
      info: {},
      histogramChartDatas: [],
      readList: [],
      readLists: [],
      loading: false,
    };
  },
  methods: {
    convertEyeType(type) {
      let result = type;
      if (!type) {
        return result;
      }
      if (type === 'OD') {
        result = '右眼';
      } else if (type === 'OS') {
        result = '左眼';
      } else if (type === 'OU') {
        result = '双眼';
      }

      return result;
    },
    // 数据处理
    processData() {
      const chartRows = Object.keys(this.info.records);
      const rowData = [];
      chartRows.forEach((key) => {
        const list = Object.keys(this.info.records[key]);
        let number = 0;
        const names = [];
        const lengths = [];
        let lists = [];
        list.forEach((keys) => {
          const value = Object.values(this.info.records[key][keys]);

          // 判断是否为调节幅度训练综合，该项训练就没有视标难度参数
          value.map((e) => {
            if (e.name?.indexOf('调节幅度训练综合') > -1) {
              e['视标难度'] = '---';
            }
            return e;
          });

          names.push(keys);
          lengths.push(value.length);
          number += value.length;
          lists = [...lists, ...value];
        });
        const data = {
          title: key,
          num: number,
          names,
          lengths,
          lists,
        };
        rowData.push(data);
        if (key === '镜片阅读训练') {
          this.getValue(lists);
        }
      });
      this.histogramChartDatas = rowData;
    },
    getNameTitle(item, type) {
      let title = '';
      if (
        type === '调节幅度训练'
        || type === '调节灵活度训练'
        || type === '镜片阅读训练'
      ) {
        title = `该分类下共进行了${item.num}项训练`;
        for (let i = 0; i < item.names.length; i++) {
          let keys = '';
          if (i === 0) {
            keys = '其中';
          }
          title = `${title}，${keys}${item.names[i]}${item.lengths[i]}次`;
        }
        title += '。';
      } else {
        title = `该分类进行了${item.num}次训练。`;
      }
      return title;
    },
    // 镜片阅读类型
    getValue(list) {
      const list1 = [];
      const list2 = [];
      list.forEach((item) => {
        if (item.镜片类型 === '正片阅读') {
          list1.push(item);
        } else if (item.镜片类型 === '负片阅读') {
          list2.push(item);
        }
      });
      this.readList = list1;
      this.readLists = list2;
    },
    // 扫视训练内容
    getContent(e) {
      let content = '';
      if (e.训练类型 === '视标模式') {
        content = `本次扫视视标个数：${e.阅读个数 || 0}个`;
      } else if (e.训练类型 === '阅读速度') {
        content = `本次最快阅读速度：${e.阅读速度 || 0}个字/s`;
      } else if (e.训练类型 === '文章阅读') {
        content = `本次阅读速度：${e.阅读页数 || 0}页/min`;
      }
      return content;
    },
    // 单项训练页面高度计算
    getHeight(name, length) {
      let height = 'auto';
      if (!this.loading) {
        return height;
      }
      if (
        name === '红闪训练—漫画故事'
        || name === '融像训练'
        || name === '红闪训练—找不同'
        || name === '光栅训练—单词模式'
        || name === '光栅训练—色图模式'
        || name === '扫视训练'
        || name === '视觉专注训练—反差阅读'
        || name === '视觉专注训练—找不同'
        || name === '眼肌运动训练'
      ) {
        if (length > 25) {
          height = '1200px';
        } else if (length > 15) {
          height = '900px';
        } else if (length > 5) {
          height = '600px';
        } else {
          height = '300px';
        }
      } else if (
        name === '调节灵活度训练'
        || name === '红闪训练—视标模式'
        || name === '光栅训练—视标模式'
      ) {
        if (length > 9) {
          height = '1800px';
        } else if (length > 6) {
          height = '1500px';
        } else if (length > 3) {
          height = '1200px';
        } else {
          height = '900px';
        }
      } else if (name === '精细目力训练' || name === '视觉专注训练—追随训练') {
        if (length > 11) {
          height = '1500px';
        } else if (length > 7) {
          height = '1200px';
        } else if (length > 5) {
          height = '900px';
        } else {
          height = '600px';
        }
      } else if (name === '脱抑制训练—红绿阅读') {
        if (length > 5) {
          height = '2100px';
        } else if (length > 4) {
          height = '1800px';
        } else if (length > 3) {
          height = '1500px';
        } else if (length > 2) {
          height = '1200px';
        } else if (length > 1) {
          height = '900px';
        } else {
          height = '600px';
        }
      } else if (name === '调节幅度训练') {
        if (length > 13) {
          height = '1200px';
        } else if (length > 8) {
          height = '900px';
        } else {
          height = '600px';
        }
      } else if (name === '脱抑制训练—捕捉训练') {
        if (length > 5) {
          height = '900px';
        } else if (length > 1) {
          height = '600px';
        } else {
          height = '300px';
        }
      } else if (name === '镜片阅读训练') {
        if (this.readList.length > 12 || this.readLists.length > 12) {
          height = '1500px';
        } else if (this.readList.length > 6 || this.readLists.length > 6) {
          height = '1200px';
        } else {
          height = '900px';
        }
      }
      return height;
    },
    // 打印
    doPrint() {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf('trident') > -1 || userAgent.indexOf('msie') > -1) {
        // eslint-disable-next-line no-alert
        alert('请使用google或者360浏览器打印');
        return;
      }
      this.loading = true;
      setTimeout(() => {
        const dom = document.getElementsByClassName('item-wrapper');
        const { length } = dom;
        // 当前页已使用高度
        let height = 190;
        for (let i = 0; i < length; i++) {
          // 单个训练高度
          // let heights = Number(dom[i].style.height.replace("px", ""));
          const heights = dom[i].offsetHeight;
          if (heights > 1200 - height) {
            // 下一页显示
            dom[i].style.marginTop = `${1200 - height}px`;
            height = heights % 1200;
          } else {
            height += heights;
          }
        }
        const thisNode = document.getElementById('print');
        thisNode.parentNode.removeChild(thisNode);
        window.print();
        window.location.reload();
      }, 1000);
    },

    convertNewHardwareData(content) {
      let result = content;
      const hardwareVersion = this.info.hardware_version;
      if (hardwareVersion && hardwareVersion !== '1.0') {
        result = content.replace(/2.50/, '2.00').replace(/1.50/, '1.00');
      }
      return result;
    },
  },
};
</script>

<style lang="less" scoped>
.item-head {
  width: 100%;
  height: 100%;
  color: #000;
  font-family: Alibaba PuHuiTi;
}
.table {
  width: 842px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 44px;
  background-color: #ffffff;
  //border: 1px solid #000;
  position: relative;
}
.logo {
  width: 182px;
  height: 24px;
  display: block;
}

.export-btn {
  position: absolute;
  right: 50px;
  top: 40px;
}

.row-head {
  height: 40px;
  margin-top: 12px;

  .title {
    font-size: 28px;
    font-weight: 800;
    color: #000;
  }

  .score {
    font-size: 12px;
    font-weight: 500;
    text-align: right;
    margin-top: 10px;
  }
}

.row-table {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;

  .ivu-col {
    padding-right: 6px;
  }
}

.item-wrapper {
  width: 100%;
  box-sizing: border-box;
  padding-top: 26px;

  .info-head {
    width: 100%;
    height: 32px;
    background: #d5dce5;

    .info-title {
      margin-left: 16px;
      font-size: 14px;
      font-weight: bold;
      line-height: 32px;
    }

    .info-titles {
      margin-left: 14px;
      font-size: 12px;
      font-weight: 400;
      line-height: 32px;
    }
  }

  .left-name {
    height: 24px;
    text-align: center;
    background: #f2f4f6;
    font-size: 12px;
    line-height: 24px;
  }

  .eye-color {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }

  .eys-type {
    display: inline-block;
    margin-right: 6px;
  }

  .item-table {
    width: 100%;
    line-height: 24px;
    text-align: center;
    margin-top: 30px;

    .table-head {
      padding: 10px 6px;
      background: #d2f2e9;
      font-size: 14px;
      font-weight: 500;
    }

    .table-row {
      padding: 10px 6px;
      font-size: 12px;
      line-height: 20px;
      align-items: center;
    }
  }

  .table_line {
    width: 80%;
    margin-left: 10%;
    margin-top: 10px;
    color: #e0dede;
    margin-bottom: 10px;
  }
}

.end-line {
  width: 100%;
  height: 1px;
  background: #b3b9c0;
  margin-top: 25px;
  margin-bottom: 25px;
}

.img-icon1 {
  float: left;
  width: 22px;
  height: 18px;
  margin-top: 6px;
}

.bottom-name {
  float: left;
  font-size: 14px;
  margin-left: 4px;
  line-height: 24px;
  line-height: 30px;
}

.bottom-line {
  float: left;
  width: 200px;
  height: 24px;
  border-bottom: 1px solid #000;
}

.company-name {
  width: 100%;
  text-align: center;
  font-size: 8px;
  color: #9d9d9d;
  margin-top: 30px;
}
</style>
